
a {
  cursor: pointer;
}

.search-bar {
  width: 60%;
}

.custom_btn {
  padding: 5px 15px !important;
  transition: all 0.4s ease-in-out;
}


.btn_div {
  width: 100%;
  text-align: right;
  margin-bottom: 0.925rem;
}

.fixedTopbar .topbar {
  z-index: 3 !important;
}


.search_bar {
  position: fixed;
  top: -4px;
  left: 350px;
  margin: 0 auto;
  z-index: 3;
  
 
}



.search_bar input {
  background: white;
  border: none;
  border-radius: 50px;
  width: 40px;
  transition: 0.4s ease-in-out;
  position: absolute;
  left: 0px;
  top: 11px;
  color: #fff;
  outline: none;
  height: 40px;
  padding-left: 35px;
}



.search_bar i {
  position: absolute;
  right: -32px;
  top: 20px;
  color: #000;
  font-weight: 400;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}




.search_bar input[value=""] {
  width: 45px ;    
}


.search_bar:hover input {
  width: 250px;
}


/* custom btn colors */

/* .btn_div .btn-primary {
  color: #fff;
  background-color: #004C97;
  border-color: #004C97;
}

.btn_div .btn-primary:hover {
  color: #fff;
  background-color: #0A3E72;
  border-color: #0A3E72;
} */

.btn_div .btn-success {
  background-color:#14BB83;
  border-color: #14BB83;
}

.btn_div .btn-success:hover {
  background-color:#12A978;
  border-color: #12A978;
}

.custom_btn a {
  color: #fff !important;
  text-decoration: none;
}

.nav-tabs li a {
  cursor: pointer;
  color: #000;
}

.nav-tabs li a.active {
  color: #2962ff !important;
}

.success_body {
  background: #fff;
  text-align: center;
}

.success_body h2 {
  color: #099c95;
  margin-bottom:0px;
}

p.MuiTablePagination-selectLabel, p.MuiTablePagination-displayedRows {
  margin:0;
}

.card-subtitle {
  display: none;
}

body .notify_select {
  min-width: 180px;
}

/* scan detail page css starts from here */

.scan_row {
  display: flex;
  margin-bottom: 5px;
  word-break: break-word;
}

.scan_row strong {
  min-width: 150px
}

.scandetail {
  width: 100%;
  display: inline-block;
}

.scandetail .card {
  padding: 10px;
  margin-bottom: 0;
  height: 100%;
}


.scandetail .card h4 {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.img_div {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.img_div .modal_img {
  max-width: 125px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modalDiv h5 {
  margin: 10px 0;
  font-weight: bold;
  color: #2353d9;
}

.scandetail .card h4 svg {
  max-width: 18px;
  margin-right: 5px;
}

.mr-2 {
  margin-right: 10px;
}

.history_div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin: 10px 0;
}

.indicator {
  margin-top: 5px;
  width: 85px;
  text-align: center;
  position: relative;
}

.history_div label {
  width: 110px;
  margin-right: 10px;
  font-weight: 600;
  font-size: 18px;
}

.indicator span {
  position: relative;
  z-index: 1;
}

.indicator span::before {
  content: "";
  width: 8px;
  height: 8px;
  display: inline-block;
  position: absolute;
  top: 8px;
  left: 5px;
  border-radius: 50px;
}

.indicator span::after {
  content: "";
  border-left: 1px dashed #777;
  position: absolute;
  width: 1px;
  height: 100px;
  left: 7px;
  top: 10px;
  z-index: -1;
}

.indicator svg {
  width: 18px; 
  border-radius: 50px;
  height: 18px;
}

/* for green dot */

.indicator span.Open svg {
  color: #FFDBDB;
  background-color: #FFDBDB; 
  border: 1px solid #F62828;
}

.indicator span.Open::before {
  background: #F62828;
}

/* for red dot */

.indicator span.Closed svg {
  color: #D4FFD1;
  background-color: #D4FFD1; 
  border: 1px solid #3CFF00;
}

.indicator span.Closed::before {
  background: #3CFF00;
}

/* for yellow dot */

.indicator span.Pending svg {
  color: #FEFAD1;
  background-color: #FEFAD1; 
  border: 1px solid #EFDE01;
}

.indicator span.Pending::before {
  background: #EFDE01;
}

/* for grey dot */

.indicator span.Read svg {
  color: #DFDFDF;
  background-color: #DFDFDF; 
  border: 1px solid #636363;
}

.indicator span.Read::before {
  background: #636363;
}

.history_div:last-child .indicator span::after {
   content: none;
}

.content_detail h5 {
  margin: 0;
}

.history_div:last-child {
 margin-bottom: 0;
}


.newStatus {
  color: #004C97;
  text-decoration: underline;
  margin-left: 20px;
  cursor: pointer;
}

.add_status .indicator span::after {
 height: 130px;
}

/* end here */

/* custom dashboard css starts */

.btn_header {
 display: flex;
 flex-direction: row;
 justify-content: center;
 width: 100%;
 margin-top: 15px;
} 

.modal-body button.layout_btn {
  width: 60%;
  margin: 0 auto 10px;
}

.dragDiv h3, .dragDiv small.text-muted {
  color: #fff !important;
}
.select-option{
  font-size: .8rem !important;
  height: 35px !important;
  width: 140px !important;
  cursor: pointer;
  margin: 0 auto !important;
}

.cstminpt {
  width: 140px !important;
  margin: 0 auto !important;
  height: 35px !important;
}

.cstmlbl {
  top: -7px !important;
  font-size: 0.8rem !important;
  left: 25px !important;
}

.cstmlbl.Mui-focused {
  top: 0px !important;
  background-color: #fff;
}

.mltichk svg {
  margin-right: 5px;
  height: 21px;
}

.tooltip {
	background: #eee;
	box-shadow: 0 0 5px #999999;
	color: #333;
	font-size: 12px;
	/* left: 130px; */
	padding: 10px;
	position: absolute;
	text-align: center;
	/* top: 95px; */
	width: 150px;
	z-index: 10;
	display: block;
	opacity: 0;
  cursor: pointer;
}
.feedbackTooltip .tooltip{
  box-shadow:none;
  background: transparent;
}
.legend {
	font-size: 12px;
}
rect {
	cursor: pointer;                                              /* NEW */
	stroke-width: 2;
}
rect.disabled {                                                 /* NEW */
	fill: transparent !important;                                 /* NEW */
}

.logoCls {
  max-width:60px;
}


.feedbackTooltip .tooltip{
  box-shadow:none;
  background: transparent;
}

iframe {
  display: none;
}

li.headTxt.nav-item {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-left: 5px;
}

.dws_logo svg {
  max-width: 60px;
  margin: 0 auto;
}


#demo-simple-select-filled{
padding-top: 15px;
}

.sidebarArea{
  z-index: 9 !important
}

.leaflet-bottom.leaflet-right {
  display: none !important;
}

.pichart-label{
  font-size: 12px;
  font-weight: 400;
}

.dws_logo {
  text-decoration: none;
}

.dws_logo h3 {
  color: #000;
  font-weight: bold;
  margin: 10px 0 0;
  font-size: 30px;
}

.mainLogo svg {
  max-width: 125px;
}

.elipse {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 151px;
  margin: 0 auto;
}

.loginBox {
  height: 100vh;
}

.btn.btn-lightblue {
    color: #fff;
    background-color: #2962ff;
    border-color: #2962ff;
    opacity: 0.65;
}

.btn.btn-lightblue:hover {
  color: #fff;
  background-color: #2353d9;
  border-color: #214ecc;
}

.badge.bg-secondary.openbadge {
  background: #F62828 !important;
}

.badge.bg-secondary.pendingbadge {
  background: #EFDE01 !important;
}

.badge.bg-secondary.closedbadge {
  background: #3CFF00 !important;
}

.pageWrapper .contentArea.fixedTopbar .container {
 width: 100%;
 max-width: 100%;
}

/* .pageWrapper .contentArea.fixedTopbar .container {
  width: 100%;
  max-width: 100%;
} */

.isMiniSidebar.pageWrapper .contentArea.fixedTopbar .container {
  max-width: 100%;
}

.fixedTopbar.contentArea {
  overflow: auto;
}

.logDiv {
  display: flex;
  text-align: center;
}

.logDiv span {
  color: #000;
  margin: 15px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  background: lightblue;
  font-size: 22px;
  transition: all 0.3s ease-in-out;
}

.logDiv span:hover {
  background: #0d6efd;
  color: #fff;
}

.custombtn {
  margin-left: -15px !important;
  position: fixed;
  top: 45%;
  border-radius: 0 5px 5px 0 !important;
}

.btn-danger.custombtn1 {
  margin-left: -58px;
  position: fixed;
  top: 50%;
  border-radius:5px 0px 0px 5px !important;
  background: #E4001C;
  border-color: #E4001C;
}

.pin-con i {
  width: 40px;
  color: #0d6efd;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  height: 40px;
  text-align: center;
}

.isMiniSidebar .pin-con i {
  color: grey;
  opacity: 0;
}




.sidebarArea:hover .pin-con i {
  opacity: 1;
}

.lbl {
  background: #fff;
  left: 15px !important;
}

.dateModal {
  position: fixed !important;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  background: rgba(0,0,0,0.5);
  padding-top: 12%;
  display: none;
  z-index: 9;
}


.innerDateModal {
  position: relative;
  width: 45%;
  margin: 0 auto;
}

.dateRangePicker {
  width: 100%;
  justify-content: center;
  padding:10px 10px 30px;
  background: #fff;
  border-radius: 10px;
}

.clsbtn {
    position: absolute;
    top: -15px;
    right: -15px;
    border-radius: 50px !important;
}

.okbtn {
  position: absolute;
  bottom: 10px;
  left: 50%;
}


.backarrow i {
  color: #2962ff;
  font-size: 18px;
  background: #fff;
  padding: 2px 5px;
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.scanMap{
  position: relative;
  height: 400px;
  width: 100%;
 
}
.durationFilter{
  display: flex;
  width: 100%;

}
.operator{
  width:30% !important;
}

.cardOuter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; 
}

.cardOuter .card {
  max-width: 165px;
  width: 165px;
  text-align: center;
  height: 140px;
  flex-direction: column;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  padding: 10px;
}

.cardOuter .card:hover {
  background: #0d6efd;
  color: #fff;
  margin-top: -10px;
}

.cardOuter .disable:hover {
  background: #fff;
  color: #0d6efd;
  transition: none;
  cursor: default;
  margin-top: 0px;
}

.cardOuter .card h6 {
  color: #0d6efd;
  margin-bottom: 10px;
  font-size: 15px;
}

.cardOuter .card:hover h6, .cardOuter .card:hover p {
  color: #fff;
}

.cardOuter .disable:hover, .cardOuter .disable:hover p  {
  background: #fff;
  color: black;
  transition: none;
  cursor: default;
  margin-top: 0px;
}
.cardOuter .disable:hover h6 {
  color: #0d6efd;
}

.card p {
  margin: 0;
  font-size: 15px;
}


.greentxt {
  color:#22C937;
  font-weight: 600;
  margin-left: 10px;
}

.redtxt {
  color:#EF2204;
  font-weight: 600;
  margin-left: 10px;
}

.reportDetails {
  background:#EFF2FE;
  padding: 15px;
  border-radius: 10px;
  height: 100%;
  transition: all 0.2s ease-in-out;
  position: relative
}

.reportDetails:hover {
  margin-top: -5px;
}

.reportDetails.verified {
  border:2px solid rgba(34,201,55,0.3);
}

.reportDetails.verified:hover{
  border-color:#22C937;
}

.reportDetails.unverified {
  border:2px solid rgba(239,34,4,0.3);
}

.reportDetails.unverified:hover{
  border-color:#EF2204;
 }

 .detailHeader {
   color: #0d6efd;
   margin-bottom: 0px;
 }

 .FilterDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* custom dashboard css ends */


.feedback_table > :not(:first-child) {
  border-top: 0 !important;
}

.feedback_table {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  width: 100%;
  overflow: hidden;
}

.feedback_table th, .feedback_table td {
  font-weight: 500;
  /* font-size: 0.875rem; */
  padding: 16px !important;
}

.font-400{
  font-weight: 400;
}

.buttonsGroups.btn-group {
  display: inline-block;
}

.buttonsGroups .btn {
 margin-bottom: 5px;
 border-radius: 5px !important;
}

.notify_select .select-option {
  margin-left: inherit !important;
}

.gmidImage {
  position: relative;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%);
  max-width: 250px;
  padding: 10px;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  margin-top: 5px;
  display: inline-block;
}

.gmidImage img {
  max-width: 200px;
}

.gmidImage i {
  position: absolute;
  top: -10px;
  cursor: pointer;
  color: #2962ff;
  right: -10px;
  background: #fff;
  line-height: 1;
  padding: 5px;
  border-radius: 50px;
  font-size: 15px;
  border: 1px solid #eee;
  box-shadow: 0 0 9px #ddd;
}

body .input-group .form-control {
  box-shadow: 0 0 5px #eee;
  border:1px solid #f5f5f5;
}

body .input-group .input-group-text {
  box-shadow: 0 0 5px #eee;
  border:1px solid #f5f5f5;
}

body .input-group .input-group-text i {
  line-height: 1;
  font-size: 18px;
  color: #0d6efd;
}

body .form-control:disabled, .form-control[readonly] {
  cursor:not-allowed;
  background: #fff;
}

/* user report redesigned */

.innerDiv {
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  box-shadow: 0 0 10px #ddd;
  background: #fff;
}

.bg_light_blue {
  background: #8BDCF9;
}

.innerTable {
  border-radius: 8px;
  /* padding: 10px; */
  width: 100%;
  box-shadow: 0 0 10px #ddd;
}

.reportHeading {
  text-align: center;
  padding: 3px 0;
}

.reportHeading h3 {
  font-size: 20px;
}

.dateSelectYear {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.dateSelect {
  width: 50%;
  box-shadow: 0 0 10px #ddd;
  background: #fff;
  border-radius: 5px;
}

.userRoleChart {
  width: 49%;
}

.userChart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.innerDiv p {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
  text-align: center;
  text-transform: capitalize;
}

.innerDiv.bg_light_blue {
  padding: 5px 0;
}

.innerDiv p span img {
  max-width: 15px;
  margin-top: -4px;
  cursor: pointer;
}

.customCard.card {
  box-shadow: none;
  background: transparent;
  padding: 0;
}

.customDashboardOuter {
  padding: 10px;
  margin-bottom: 10px;
  background: #fff;
}

.topCustomBtn {
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
}

.innerCustomCard {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%);
  background: #fff;
  padding: 15px;
  border-radius: 5px;
}

.boxShadowNone {
  box-shadow: none !important;
  background: transparent !important; 
}

.mainContentOuter {
  width: 100%;
  padding: 0.925rem 0;
  max-width: 100%;
  margin: 0 auto;
}

.customChartSmall canvas:first-child {
  width: 200px!important;
  height: 200px!important;
  right: 0!important;
  margin: 0 auto;
}

.customChartBig canvas:first-child {
  left: -20% !important;
  width: auto !important;
  height: 245px !important;
  top: 15px !important;
}

.icons_edit_delete {
  position: absolute;
  right: 0;
}

.transparent_btn {
  background: transparent !important;
  border: 0 !important;
}

.transparent_btn i {
  color: #2353d9;
  font-size: 20px;
}

.btn.light_btn {
  background: #ecf1ff;
  color: #2962ff;
  border-color:#2962ff;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.btn.light_btn:hover, .btn.light_btn:focus {
  background-color:#2962ff;
  border-color:#2962ff;
} 

.toggle-button {
  position: absolute;
  padding-top: 30px;
  padding-left: 20px;
  top: 0;
  left: 0;
  display: inline-flex;
  align-items: center;
}

.dragDiv .toggle-button {
  padding-top: 45px;
}

.dragDiv .toggle-button p {
  font-weight: bold;
}

.bd1 {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.clear_filter_btn {
  float: right;
  margin: 10px;
}

.cstm_hgt {
  height: 390px;
  overflow-y: auto;
}

/* custom scroll bar */

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #777, #777 0px, #ddd 0, #ddd);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #777;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.MuiBox-root:empty {
  display: none;
}

.submenu_dropdown > a.activeLink {
  color: inherit !important;
  opacity: 0.6 !important;
}

.activeLink {
  color: #2962ff !important;
  opacity: 1 !important;
}
/* a.save-active[href='/tag/list'] {
  background: red;
} */

/* responsive media queries starts */
  
@media screen and (min-width: 1920px) {
  /* For Projectors or Higher Resolution Screens (Full HD) */

}

@media screen and (min-width: 3840px) {
  /* //For 4K Displays (Ultra HD) */
}

/*============================= Tablet landscape size to standard 960  =============================*/
@media only screen and (min-width: 959px) and (max-width: 1024px) {
  .loginContainer.col-lg-3 {
    width: 50%;
  }

  body .custom_col_100 {
    width: 100%;
  }

}

/* ============================= global tablet and mobile =============================*/
@media only screen and (max-width: 959px) {

  /* .sidebarArea {
    z-index: 3 !important;
    top: 60px;
  } */

  .back-view {
    flex-direction: row !important;
    margin-left: 10px;
  }

  .dws_logo h3 {
    color: #fff;
    margin: 0;
  }

  .leaflet-container {
    width: 100% !important;
    z-index: 1;
  }

  body .btn.mob_menu {
    background: #2353d9;
    line-height: 1;
    padding: 3px 5px;
  }

  .mob_menu i {
    font-size: 25px;
    color: #fff;
    opacity: 1;
  }

  .topbar .navbar-brand {
    display: none !important;
  }

  .pin-con {
    display: none;
  }

  .sidebarBox .btn-close {
    opacity: 1;
    top: -24px;
    margin: 0;
    padding: 0;
    position: relative;
  }

  body .sidebarArea .nav-link {
    padding: 5px;
  }

  body .sidebarArea .subMenu .nav-link {
    padding: 5px 15px;
  }

  .customizerSidebar {
    display: none;
  }

 

}

/* ================Tablet Portrait size to standard 960 (devices and browsers) ================*/
@media only screen and (min-width: 768px) and (max-width: 959px) {

  

}

/* ==================All Mobile Sizes (devices and browser) ==================*/

@media only screen and (max-width: 767px) {

  .mob_m_20 {
    margin-bottom: 20px;
  }

  body .mob_p_15 {
    padding: 15px !important;
  }

  body .mob_p_10 {
    padding: 10px !important;
  }

  body .mob_mt_15 {
    margin-top: 15px;
  }

  body .mob_br_5 {
    border-radius: 5px !important;
  }

  .pageWrapper .contentArea.fixedTopbar .container {
    padding: 0;
  }

  .mainContentOuter {
    padding: 0;
  }

  .topbar .dropdown-menu {
    max-width: 300px;
  }

  body .fixedTopbar.contentArea {
    padding-top: 70px;
  }

  li.headTxt.nav-item {
    font-size: 15px;
  }
  
  .cardOuter {
    display: inline-block;
    width: 100%;
  }

  .cardOuter .card {
    margin: 5px;
    width: 175px;
    max-width: 100%;
    float: left;
  }

  
  


}

/* ===================Mobile Landscape Size to Tablet Portrait (devices and browsers)=================== */
@media only screen and (min-width: 480px) and (max-width: 767px) {

  .cardOuter .card{
    width: 210px;
  }



}

/* =====================Mobile Portrait Size to Mobile Landscape Size (devices and browsers)===================== */

@media screen and (max-width: 479px) {

  .app__stats {
    flex-direction: column;
  }

  .logDiv {
    display: inline-block;
  }

  .logDiv span {
    height: 150px;
  }

  .dws_logo h3 {
    font-size: 25px;
  }

  .userChart {
    display: inline-block;
  }

  .userRoleChart {
    width: 100%;
  }

  .userRoleChart:first-child {
    margin-bottom: 30px;
  }

  .customChartBig canvas:first-child {
    left: -10% !important;
    width: auto !important;
    height: 210px !important;
    top: 30px !important;
  }

  body .infoBox{
    margin-bottom: 10px;
  }

  body .btn_div .custom_btn {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .mob_p_0 {
    padding: 0 !important;
  }
  .customizerSidebar {
    display: none;
  }

  .scandetail .card {
    overflow: auto;
  }

  .indicator {
    width: auto;
    max-width: 85px;
  }
  
  li.headTxt.nav-item {
    max-width: 150px;
  }

 

}

/* responsive media queries ends */